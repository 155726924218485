import { BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { useRootStore } from 'src/store/root';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { TransactionResponse } from '@ethersproject/providers';
import { useModalContext } from '../../../hooks/useModal';
import { Trans } from '@lingui/macro';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { queryClient } from '../../../../pages/_app.page';
import { QueryKeys } from '../../../ui-config/queries';

export interface SupplyPositionActionsProps extends BoxProps {
  marketId?: number;
  tokenId?: string;
  isWrongNetwork: boolean;
}

export const SupplyPositionActions = React.memo(
  ({ marketId, tokenId, isWrongNetwork }: SupplyPositionActionsProps) => {
    const { setGasLimit, setMainTxState, mainTxState, setTxError } = useModalContext();
    const { sendTx } = useWeb3Context();

    const [
      supplyPosition,
      estimateGasLimit,
    ] = useRootStore((state) => [
      state.supplyPosition,
      state.estimateGasLimit,
      state,
    ]);

    const action = async () => {
      if (!marketId || !tokenId || mainTxState.success) return;

      try {
        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true });

        const supplyTx = supplyPosition(marketId, tokenId);
        const supplyTxData = await estimateGasLimit(supplyTx);

        const response: TransactionResponse = await sendTx(supplyTxData);
        await response.wait(1);

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

        queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.SUPPLIED_POSITIONS_DATA] });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    // Update gas estimation
    useEffect(() => {
      if (!marketId || !tokenId || mainTxState.success) return;

      const calculateGasLimit = async () => {
        const supplyTx = supplyPosition(marketId, tokenId);
        const supplyTxData = await estimateGasLimit(supplyTx);
        setGasLimit(supplyTxData.gasLimit?.toString() || '0');
      }
      calculateGasLimit();
      const timerId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(timerId);
      }
    }, [mainTxState.success]);

    return (
      <TxPositionActionsWrapper
        mainTxState={mainTxState}
        isWrongNetwork={isWrongNetwork}
        requiresAmount
        preparingTransactions={false}
        actionText={<Trans>Supply Position</Trans>}
        actionInProgressText={<Trans>Supplying Position</Trans>}
        handleAction={() => {
          window.gtag('event', 'lending_supply_modal_confirm', {
            asset: 'Uniswap position',
          });
          return action();
        }}
      />
    );
  }
);
