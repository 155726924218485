import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../../components/primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { SupplyPositionModalContent } from './SupplyPositionModalContent';

export const SupplyPositionModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    tokenId: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.SupplyPosition} setOpen={close}>
      <PositionModalWrapper
        tokenId={args.tokenId}
        title="Supply"
      >
        {(params) => <SupplyPositionModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
